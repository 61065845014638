<template>
  <div class="d-flex align-center">
    <v-select
      :items="types"
      :value="selected"
      :label="label"
      :loading="isLoading"
      item-text="name"
      return-object
      :disabled="disabled"
      @change="emitInput"
      :rules="rules"
      clearable
    />
    <table-action-button
      icon="add_circle"
      text="Adicionar novo tipo"
      @click="createType"
    />
    <form-type ref="form" @create="createdType" />
  </div>
</template>

<script>
import { findTypes } from "@/services/oligoelements-service";
import { mapGetters } from "vuex";
import FormType from "@/components/Oligoelements/FormType";
export default {
  components: {
    FormType: () => import("@/components/Oligoelements/FormType"),
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => {},
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    types: [],
    selected: {},
  }),
  mounted() {
    if (this.value) {
      this.selected = this.value;
    }
    this.list();
  },
  watch: {
    value(value) {
      this.selected = value;
    },
  },
  computed: {
    ...mapGetters("loading", ["isLoading"]),
  },
  methods: {
    createdType(type) {
      this.selected = type;
      this.types.push(type);
      this.emitInput(type);
    },
    createType() {
      this.$refs.form.create();
    },
    async list() {
      try {
        let { data } = await findTypes();
        this.types = data;
      } catch (error) {
        this.$errorHandler(error);
      }
    },
    emitInput(value) {
      this.$emit("input", value);
      this.$emit("change", value);
    },
  },
};
</script>

<style></style>
